import styled from "styled-components";
import { StylesToSmall } from "components/atoms/typography/typography.styled";
import { spacing } from "helpers/conversion-style";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";

export const StyledPasswordWrapper = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledLoginForm = styled.form`
    margin-bottom: 32px;
`;

export const StyledLoginInput = styled.div`
    margin-bottom: ${spacing(12)};
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(28)};
`;

export const StyledPasswordLink = styled(CustomLink)`
    ${StylesToSmall};
    color: ${({theme}) => theme.text?.muted};
`;
